<template>
  <div
    class="modal fade"
    id="voucher_modal"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="form-group required">
          <label>Voucher Code</label>
          <input type="text" name="voucher_code" class="form-control" />
        </div>
        <div class="form-group">
          <button class="btn" type="submit">Add Code</button>
        </div>
        <p>Please note that you can only use each code once.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
