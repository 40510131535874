<template>
  <section class="payment_section">
    <div class="container">
      <form action="#" method="POST">
        <!-- payment section start -->
        <Billing></Billing>
        <!-- payment section end -->

        <!-- voucher modal start -->
        <Voucher></Voucher>
        <!-- voucher modal end -->
      </form>
    </div>
  </section>
</template>

<script>
import Billing from "@/components/frontend/payment/Billing.vue";
import Voucher from "@/components/frontend/payment/Voucher.vue";
export default {
  name: "PaymentSection",
  components: {
    Billing,
    Voucher,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
@import "@/assets/frontend/style/master.scss";
@import "@/assets/frontend/style/payment.scss";
</style>
