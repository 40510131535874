<template>
  <section class="payment_section">
    <div class="container">
      <div v-if="!accessToken" class="row mobile-login">
        <div class="payment_container col-md-12">
          <div class="col-lg-10 offset-lg-1">
            <p @click="showModal = true" class="login-ar"><span>Click here to login.</span></p>
          </div>
          <div v-if="showModal">
            <transition name="modal">
              <div class="modal-mask">
                <div class="modal-wrapper">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title">login</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true" @click="showModal = false">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-lg-12">
                            <div class="checkout_form">
                              <ValidationObserver>
                                <form name="login" @submit.prevent="customerLogin">
                                  <div class="form-group required">
                                    <label>Name / Email</label>
                                    <ValidationProvider rules="email|required" v-slot="{ errors }">
                                      <input class="form-control" v-model="user.email" name="email" type="email" />
                                      <span style="color: red">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                  </div>
                                  <div class="form-group required">
                                    <label>Password </label>
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                      <input type="password" v-model="user.password" name="password"
                                        class="form-control" />
                                      <span style="color: red">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                    <span style="color: red" v-if="resError">{{ resError }}</span>
                                  </div>
                                  <div class="form-group">
                                    <button class="btn">Login</button>
                                    <router-link class="btn" :to="{ name: 'registration' }">
                                      Sign up</router-link>
                                  </div>
                                  <a href="#" class="forgot">Forgot Password <span>?</span></a>
                                </form>
                              </ValidationObserver>
                            </div>
                          </div>
                          <div class="col-lg-12">
                            <div class="others_form">
                              <ul class="btn_list">
                                <li>
                                  <a @click="googleLogin" style="cursor: pointer" class="google"><i
                                      class="icon ion-logo-googleplus"></i> Continue To
                                    Google</a>
                                </li>
                                <li>
                                  <a @click="loginFacebook" style="cursor: pointer" class="facebook"><i
                                      class="icon ion-logo-facebook"></i> Continue
                                    Facebook</a>
                                </li>
                                <!--                  <li>
                                                    <a @click="goToPayment()" class="guest"
                                                      ><i class="icon ion-md-person"></i> Continue As Guest</a
                                                    >
                                                  </li>-->
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="showModal = false">
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
          </div>
          <p class="text-center">Or</p>

        </div>

      </div>
      <div class="row">
        <ValidationObserver v-slot="{ invalid }">
          <form @submit="bill">
            <div class="payment_container">
              <div class="col-lg-10 offset-lg-1">
                <div class="row">
                  <div class="col-md-6">
                    <h4 class="title">Shipping Address</h4>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="form-group required">
                              <label>Name</label>
                              <ValidationProvider rules="required|minName:3" v-slot="{ errors }">
                                <input type="text" name="name" v-model="shipping.name" class="form-control" />
                                <span style="color: red">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group required">
                              <label>Phone </label>
                              <ValidationProvider rules="numeric|length:11|required" v-slot="{ errors }">
                                <input type="text" name="phone" v-model="shipping.phone" class="form-control" />
                                <span style="color: red">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group">
                              <label>Email address
                                <span style="color: red">(Optional)</span></label>
                              <ValidationProvider rules="email" v-slot="{ errors }">
                                <input type="text" name="email" v-model="shipping.email" class="form-control" />
                                <span style="color: red">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </div>

                          <div class="col-md-12">
                            <div class="form-group required">
                              <label>Address </label>
                              <ValidationProvider rules="required" v-slot="{ errors }">
                                <input type="text" name="Address" v-model="shipping.address" class="form-control" />
                                <span style="color: red">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </div>
                          </div>

                          <div class="col-md-4">
                            <div class="form-group required">
                              <label>Division </label>
                              <ValidationProvider name="division" rules="required" v-slot="{ errors }">
                                <b-form-group>
                                  <b-form-select id="division" value-field="districts" text-field="name"
                                    v-model="divisions" :options="locations" required></b-form-select>
                                </b-form-group>
                                <span v-if="errors != ''" style="color: red">{{
                                  errors[0]
                                  }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group required">
                              <label>District </label>
                              <ValidationProvider name="District" rules="required" v-slot="{ errors }">
                                <b-form-group>
                                  <b-form-select id="district" value-field="areas" text-field="name" v-model="districts"
                                    :options="divisions" required></b-form-select>
                                </b-form-group>
                                <span v-if="errors != ''" style="color: red">{{
                                  errors[0]
                                  }}</span>
                              </ValidationProvider>
                            </div>
                          </div>
                          <div class="col-md-4">
                            <div class="form-group">
                              <label>Area </label>
                              <ValidationProvider name="Area">
                                <b-form-group>
                                  <b-form-select id="area" value-field="id" text-field="name" v-model="shipping.area_id"
                                    :options="areas"></b-form-select>
                                </b-form-group>
                                <!--<span v-if="errors != ''" style="color: red">{{
                                  errors[0]
                                }}</span>-->
                              </ValidationProvider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div v-if="!accessToken" class="col-md-6 desktop-login">
                    <div class="col-lg-12">
                      <h4 class="title">Account</h4>
                      <p>Welcome! Please login to continue </p>
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="checkout_form">
                            <div class="form-group required">
                              <label>Name / Email</label>
                              <input class="form-control" v-model="user.email" name="email" type="email" />
                            </div>
                            <div class="form-group required">
                              <label>Password </label>

                              <input type="password" v-model="user.password" name="password" class="form-control" />
                              <span style="color: red" v-if="resError">{{ resError }}</span>
                            </div>
                            <div class="form-group">
                              <button class="btn" type="button" @click="customerLogin">Login</button>
                              <router-link class="btn" :to="{ name: 'registration' }">
                                Sign up</router-link>
                            </div>
                            <a href="#" class="forgot">Forgot Password <span>?</span></a>

                          </div>
                        </div>
                        <div class="col-lg-12">
                          <div class="others_form">
                            <ul class="btn_list">
                              <li>
                                <a @click="googleLogin" style="cursor: pointer" class="google"><i
                                    class="icon ion-logo-googleplus"></i> Continue To
                                  Google</a>
                              </li>
                              <li>
                                <a @click="loginFacebook" style="cursor: pointer" class="facebook"><i
                                    class="icon ion-logo-facebook"></i> Continue
                                  Facebook</a>
                              </li>
                              <!--                  <li>
                                                            <a @click="goToPayment()" class="guest"
                                                              ><i class="icon ion-md-person"></i> Continue As Guest</a
                                                            >
                                                          </li>-->
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="col-md-12 " style="padding: 0">
                      <h4 class="title billing_i">
                        Billing Address
                        <!--{{ locations[0].id}}-->
                        <input type="checkbox" tabindex="112" value="0" v-model="selectedBilling" class="myCheckbox"
                          id="sameasshippingaddresscheckbox" name="sameasshippingaddresscheckbox"
                          onclick="showorhideCheekedOpt(selectedBilling);" />
                        <i> (Same as shiping address )</i>
                      </h4>
                    </div>
                    <div v-if="!selectedBilling" class="col-md-12" style="padding: 0">
                      <div class="row" id="billingAddressdiv">
                        <div class="col-md-12">
                          <div class="form-group required">
                            <label>Name</label>
                            <ValidationProvider rules="required|minName:3" v-slot="{ errors }">
                              <input type="text" name="name" v-model="shipping.billing_name" class="form-control" />
                              <span style="color: red">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group required">
                            <label>Phone </label>
                            <ValidationProvider rules="numeric|length:11|required" v-slot="{ errors }">
                              <input type="text" name="phone" v-model="shipping.billing_phone" class="form-control" />
                              <span style="color: red">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Email address
                              <span style="color: red">(Optional)</span></label>
                            <ValidationProvider rules="email" v-slot="{ errors }">
                              <input type="text" name="email" v-model="shipping.billing_email" class="form-control" />
                              <span style="color: red">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group required">
                            <label>Address </label>
                            <ValidationProvider rules="required" v-slot="{ errors }">
                              <input type="text" name="Address" v-model="shipping.billing_address"
                                class="form-control" />
                              <span style="color: red">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group required">
                            <label>Division </label>

                            <ValidationProvider name="division" rules="required" v-slot="{ errors }">
                              <b-form-group>
                                <b-form-select id="division" value-field="districts" text-field="name"
                                  v-model="divisionsBilling" :options="locations" required></b-form-select>
                              </b-form-group>
                              <span v-if="errors != ''" style="color: red">{{
                                errors[0]
                                }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group required">
                            <label>District </label>
                            <ValidationProvider name="District" rules="required" v-slot="{ errors }">
                              <b-form-group>
                                <b-form-select id="district" value-field="areas" text-field="name"
                                  v-model="districtsBilling" :options="divisionsBilling" required></b-form-select>
                              </b-form-group>
                              <span v-if="errors != ''" style="color: red">{{
                                errors[0]
                                }}</span>
                            </ValidationProvider>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label>Area </label>
                            <ValidationProvider name="Area">
                              <b-form-group>
                                <b-form-select id="area" value-field="id" text-field="name"
                                  v-model="shipping.billing_area_id" :options="areasBilling"></b-form-select>
                              </b-form-group>

                            </ValidationProvider>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>&nbsp;</label>
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="newsletter" />
                        <label class="form-check-label" for="newsletter">Sing Up for Newsletter</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Additional notes
                        <span style="color: red">(Optional)</span></label>
                      <textarea class="form-control" rows="3" v-model="shipping.comments"></textarea>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <div class="form-check">
                        <ValidationProvider :rules="{ required: { allowFalse: false } }" v-slot="{ errors }"
                          name="terms">
                          <input class="form-check-input" type="checkbox" id="terms" name="terms"
                            v-model="shipping.terms_and_condition" />
                          <label class="form-check-label" for="terms">I agree to the <span>terms & conditions</span> and
                            the privacy policy.</label>
                          <br />
                          <span style="color: red">{{ errors[0] }}</span>
                        </ValidationProvider>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="payment_container mt-3">
              <div class="col-lg-10 offset-lg-1">
                <h4 class="title">Payment</h4>
                <div class="row">
                  <div class="col-xl-6 col-lg-7">
                    <div class="payment_product">
                      <div class="product_box" v-for="(item, index) in shoppingBag" :key="index">


                        <img v-if="item.cartImage" :src="'https://savershall.com/suadmin/' + item.cartImage
                          " alt="" />
                        <div class="product_article">
                          <div class="product_title">
                            <h5>{{ item.name }}</h5>
                            
                              <!-- <span v-if="$store.getters.countGroupProduct(item.group_product_id) > 0"> Same Group Qty: {{ item.group_product_id }}</span> -->
                            
                            <div class="short-desc" v-html="item.short_description"></div>
                            <p v-if="!item.short_description">&nbsp;</p>
                          </div>
                          <div @click="removeFromCart(item)" class="btn ml-3 product-trash" type="submit">
                            <i class="icon ion-md-trash"></i>
                          </div>
                          <div class="price_qty">
                            <div class="group product-pricing">
                              <!--<label>Qty: {{item.qty}}</label>-->
                              <button type="button" class="minus" @click="deductQty(item)">
                                <i class="icon ion-md-remove"></i>
                              </button>
                              <div class="product_value">
                                <span class="quantity_value">
                                  {{ $store.getters.prodQty(item.id) }}
                                </span>
                              </div>
                              <button :disabled="item.product_price[0].quantity ===
                                $store.getters.prodQty(item.id)
                                " type="button" class="plus" @click="addQty(item)">
                                <i class="icon ion-md-add"></i>
                              </button>
                            </div>
                            <!--<h4>৳ {{ item.unitPrice}}</h4>-->
                            <template>
                              <div v-if="$store.getters.countGroupProduct(item.group_product_id) > 0">


                                <h5 class="unitPrice"
                                  v-if="item.product_price[0].tier_quantity_three > 0 && $store.getters.countGroupProduct(item.group_product_id) >= item.product_price[0].tier_quantity_three">
                                  ৳ {{ item.product_price[0].tier_price_three }}
                                </h5>

                                <h5 class="unitPrice"
                                  v-else-if="$store.getters.countGroupProduct(item.group_product_id) >= item.product_price[0].tier_quantity_two && item.product_price[0].tier_quantity_two !== 0 && $store.getters.countGroupProduct(item.group_product_id) < item.product_price[0].tier_quantity_three">
                                  ৳ {{ item.product_price[0].tier_price_two }}</h5>
                                <h5 class="unitPrice"
                                  v-else-if="$store.getters.countGroupProduct(item.group_product_id) >= item.product_price[0].tier_quantity_two && item.product_price[0].tier_quantity_two !== 0 && item.product_price[0].tier_price_three === 0">
                                  ৳ {{ item.product_price[0].tier_price_two }}</h5>
                                <!--T1-->
                                <!--                <h4 class="uPrice" v-else-if="item.product_price[0].tier_quantity_one>0 &&  $store.getters.countGroupProduct(item.group_product_id) >= item.product_price[0].tier_quantity_one && $store.getters.countGroupProduct(item.group_product_id) < item.product_price[0].tier_quantity_two ">৳ {{item.product_price[0].tier_price_one}} </h4>-->
                                <h5 class="unitPrice"
                                  v-else-if="$store.getters.countGroupProduct(item.group_product_id) >= item.product_price[0].tier_quantity_one && item.product_price[0].tier_quantity_one !== 0 && $store.getters.countGroupProduct(item.group_product_id) < item.product_price[0].tier_quantity_two">
                                  ৳ {{ item.product_price[0].tier_price_one }} </h5>
                                <h5 class="unitPrice"
                                  v-else-if="$store.getters.countGroupProduct(item.group_product_id) >= item.product_price[0].tier_quantity_one && item.product_price[0].tier_quantity_one !== 0 && item.product_price[0].tier_price_two === 0">
                                  ৳ {{ item.product_price[0].tier_price_one }}
                                </h5>
                                <h5 class="unitPrice"
                                  v-else-if="item.product_price[0].offer_price > 0 && $store.getters.prodQty(item.id)">
                                  ৳{{ item.product_price[0].offer_price }} </h5>
                                <h5 class="unitPrice" v-else> ৳ {{ item.product_price[0].price }}</h5>
                              </div>


                              <div v-else>
                                <h5 class="unitPrice"
                                  v-if="item.product_price[0].tier_quantity_three > 0 && $store.getters.prodQty(item.id) >= item.product_price[0].tier_quantity_three">
                                  ৳ {{ item.product_price[0].tier_price_three }}
                                </h5>

                                <h5 class="unitPrice"
                                  v-else-if="$store.getters.prodQty(item.id) >= item.product_price[0].tier_quantity_two && item.product_price[0].tier_quantity_two !== 0 && $store.getters.prodQty(item.id) < item.product_price[0].tier_quantity_three">
                                  ৳ {{ item.product_price[0].tier_price_two }}</h5>
                                <h5 class="unitPrice"
                                  v-else-if="$store.getters.prodQty(item.id) >= item.product_price[0].tier_quantity_two && item.product_price[0].tier_quantity_two !== 0 && item.product_price[0].tier_price_three === 0">
                                  ৳ {{ item.product_price[0].tier_price_two }}</h5>
                                <!--T1-->
                                <!--                <h4 class="uPrice" v-else-if="item.product_price[0].tier_quantity_one>0 &&  $store.getters.prodQty(item.id) >= item.product_price[0].tier_quantity_one && $store.getters.prodQty(item.id) < item.product_price[0].tier_quantity_two ">৳ {{item.product_price[0].tier_price_one}} </h4>-->
                                <h5 class="unitPrice"
                                  v-else-if="$store.getters.prodQty(item.id) >= item.product_price[0].tier_quantity_one && item.product_price[0].tier_quantity_one !== 0 && $store.getters.prodQty(item.id) < item.product_price[0].tier_quantity_two">
                                  ৳ {{ item.product_price[0].tier_price_one }} </h5>
                                <h5 class="unitPrice"
                                  v-else-if="$store.getters.prodQty(item.id) >= item.product_price[0].tier_quantity_one && item.product_price[0].tier_quantity_one !== 0 && item.product_price[0].tier_price_two === 0">
                                  ৳ {{ item.product_price[0].tier_price_one }}
                                </h5>
                                <h5 class="unitPrice"
                                  v-else-if="item.product_price[0].offer_price > 0 && $store.getters.prodQty(item.id)">
                                  ৳{{ item.product_price[0].offer_price }} </h5>
                                <h5 class="unitPrice" v-else> ৳ {{ item.product_price[0].price }}</h5>
                              </div>
                            </template>


                            <template>

                              <div v-if="$store.getters.countGroupProduct(item.group_product_id) > 0">
                                <h4
                                  v-if="item.product_price[0].tier_quantity_three > 0 && $store.getters.countGroupProduct(item.group_product_id) >= item.product_price[0].tier_quantity_three">
                                  ৳{{ item.product_price[0].tier_price_three * $store.getters.prodQty(item.id) }}</h4>
                                <h4
                                  v-else-if="$store.getters.countGroupProduct(item.group_product_id) >= item.product_price[0].tier_quantity_two && item.product_price[0].tier_quantity_two !== 0 && $store.getters.prodQty(item.id) < item.product_price[0].tier_quantity_three">
                                  ৳{{ item.product_price[0].tier_price_two * $store.getters.prodQty(item.id) }}</h4>
                                <h4
                                  v-else-if="$store.getters.countGroupProduct(item.group_product_id) >= item.product_price[0].tier_quantity_two && item.product_price[0].tier_quantity_two !== 0 && item.product_price[0].tier_price_three === 0">
                                  ৳{{ item.product_price[0].tier_price_two * $store.getters.prodQty(item.id) }}</h4>
                                <h4
                                  v-else-if="$store.getters.countGroupProduct(item.group_product_id) >= item.product_price[0].tier_quantity_one && item.product_price[0].tier_quantity_one !== 0 && $store.getters.countGroupProduct(item.group_product_id) < item.product_price[0].tier_quantity_two">
                                  ৳{{ item.product_price[0].tier_price_one * $store.getters.prodQty(item.id) }} </h4>
                                <h4
                                  v-else-if="$store.getters.countGroupProduct(item.group_product_id) >= item.product_price[0].tier_quantity_one && item.product_price[0].tier_quantity_one !== 0 && item.product_price[0].tier_price_two === 0">
                                  ৳{{ item.product_price[0].tier_price_one * $store.getters.prodQty(item.id) }} </h4>

                                <h4
                                  v-else-if="item.product_price[0].offer_price > 0 && $store.getters.prodQty(item.id)">
                                  ৳{{ item.product_price[0].offer_price * $store.getters.prodQty(item.id) }}</h4>
                                <h4 v-else>৳{{ item.product_price[0].price * $store.getters.prodQty(item.id) }}</h4>
                              </div>
                              <div v-else>
                                <h4
                                  v-if="item.product_price[0].tier_quantity_three > 0 && $store.getters.prodQty(item.id) >= item.product_price[0].tier_quantity_three">
                                  ৳{{ item.product_price[0].tier_price_three * $store.getters.prodQty(item.id) }}</h4>
                                <h4
                                  v-else-if="$store.getters.prodQty(item.id) >= item.product_price[0].tier_quantity_two && item.product_price[0].tier_quantity_two !== 0 && $store.getters.prodQty(item.id) < item.product_price[0].tier_quantity_three">
                                  ৳{{ item.product_price[0].tier_price_two * $store.getters.prodQty(item.id) }}</h4>
                                <h4
                                  v-else-if="$store.getters.prodQty(item.id) >= item.product_price[0].tier_quantity_two && item.product_price[0].tier_quantity_two !== 0 && item.product_price[0].tier_price_three === 0">
                                  ৳{{ item.product_price[0].tier_price_two * $store.getters.prodQty(item.id) }}</h4>
                                <h4
                                  v-else-if="$store.getters.prodQty(item.id) >= item.product_price[0].tier_quantity_one && item.product_price[0].tier_quantity_one !== 0 && $store.getters.prodQty(item.id) < item.product_price[0].tier_quantity_two">
                                  ৳{{ item.product_price[0].tier_price_one * $store.getters.prodQty(item.id) }} </h4>
                                <h4
                                  v-else-if="$store.getters.prodQty(item.id) >= item.product_price[0].tier_quantity_one && item.product_price[0].tier_quantity_one !== 0 && item.product_price[0].tier_price_two === 0">
                                  ৳{{ item.product_price[0].tier_price_one * $store.getters.prodQty(item.id) }} </h4>

                                <!--                <h4 v-else-if="item.product_price[0].tier_quantity_two>0 && $store.getters.prodQty(item.id) >= item.product_price[0].tier_quantity_two && $store.getters.prodQty(item.id) < item.product_price[0].tier_quantity_three ">৳ {{item.product_price[0].tier_price_two * $store.getters.prodQty(item.id)}}</h4>-->
                                <!--                <h4 v-else-if="item.product_price[0].tier_quantity_one>0 &&  $store.getters.prodQty(item.id) >= item.product_price[0].tier_quantity_one && $store.getters.prodQty(item.id) < item.product_price[0].tier_quantity_two ">৳ {{item.product_price[0].tier_price_one * $store.getters.prodQty(item.id)}} </h4>-->
                                <h4
                                  v-else-if="item.product_price[0].offer_price > 0 && $store.getters.prodQty(item.id)">
                                  ৳{{ item.product_price[0].offer_price * $store.getters.prodQty(item.id) }}</h4>
                                <h4 v-else>৳{{ item.product_price[0].price * $store.getters.prodQty(item.id) }}</h4>
                              </div>
                            </template>
                            <!--                            <template>
                              <h4 v-if="item.product_price[0].tier_quantity_three > 0 && $store.getters.prodQty(item.id) >= item.product_price[0].tier_quantity_three ">
                                ৳
                                {{ item.product_price[0].tier_price_three * $store.getters.prodQty(item.id) }}
                              </h4>
                              <h4  v-else-if=" item.product_price[0].tier_quantity_two > 0 && $store.getters.prodQty(item.id) >= item.product_price[0].tier_quantity_two && $store.getters.prodQty(item.id) < item.product_price[0].tier_quantity_three ">
                                ৳ {{item.product_price[0].tier_price_two * $store.getters.prodQty(item.id) }}
                              </h4>
                              <h4  v-else-if="                        item.product_price[0].tier_quantity_one > 0 &&
                                $store.getters.prodQty(item.id) >=
                                  item.product_price[0].tier_quantity_one &&
                                $store.getters.prodQty(item.id) <
                                  item.product_price[0].tier_quantity_two
                              "
                              >
                                ৳
                                {{
                                  item.product_price[0].tier_price_one *
                                  $store.getters.prodQty(item.id)
                                }}
                              </h4>
                              <h4
                                  v-else-if="
                                item.product_price[0].offer_price > 0 &&
                                $store.getters.prodQty(item.id)
                              "
                              >
                                ৳
                                {{
                                  item.product_price[0].offer_price *
                                  $store.getters.prodQty(item.id)
                                }}
                              </h4>
                              <h4 v-else>
                                ৳
                                {{
                                  item.product_price[0].price *
                                  $store.getters.prodQty(item.id)
                                }}
                              </h4>
                            </template>-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-lg-5">
                    <div class="payment_total">
                      <table class="table">
                        <tr>
                          <td>Total products :</td>
                          <td>
                            BDT. <span>{{ total_amt }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td>Delivery charge :</td>
                          <td>
                            BDT. <span>{{ shippingCharge.shipping_charge }}</span>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <div v-if="!isApplyCoupon" class="input-group">
                              <input type="text" placeholder="Coupon Code" v-model="coupon_code" class="form-control">
                              <div class="input-group-append">
                                <div @click="applyCoupon(coupon_code)" class="btn btn-primary">Apply</div>
                              </div>
                            </div>
                            <p v-if="coupon_msg !== ''" style="color: green">{{ coupon_msg }}</p>
                          </td>
                        </tr>
                        <tr class="bordert">
                          <th>Grand Total :</th>
                          <th>
                            BDT.
                            <span>{{
                              total_amt + shippingCharge.shipping_charge
                              }}</span>
                          </th>
                        </tr>
                      </table>
                      <form action="">
                        <p style="color: red; padding-top: 15px">{{ siteSetting("shipping-cart-note").title }}</p>
                        <p>Payment method</p>
                        <hr />
                        <p>
                          <label for="cod">Cash On Delivery</label>
                          <input v-model="deleveryMethod" id="cod" value="true" type="radio" />
                        </p>
                        <figure class="payment_option">
                          <img :disabled="deleveryMethod !== true" :src="'https://savershall.com/suadmin/storage/images/setting/' +
                            paymentMethod.image
                            " alt="" :key="index" v-for="(
                          paymentMethod, index
                        ) in paymentMethods.site_details.filter(
                            (e) => e.title === 'Cash On Delivery'
                          )" />
                        </figure>

                        <p>
                          <label for="cod">Payment with Bkash</label>
                          <input v-model="deleveryMethod" id="bkash" value="2" type="radio" />
                        </p>
                        <figure class="payment_option">
                          <img
                            v-for="(paymentMethod, index) in paymentMethods.site_details.filter((e) => e.title === 'Bkash')"
                            :key="index"
                            :src="'https://savershall.com/suadmin/storage/images/setting/' + paymentMethod.image"
                            alt="" />
                        </figure>
                        <p>
                          <label for="op">Online Payment</label>
                          <input v-model="deleveryMethod" id="op" value="3" type="radio" />
                        </p>
                        <figure class="payment_option">
                          <img :src="'https://savershall.com/suadmin/storage/images/setting/' +
                            paymentMethod.image
                            " alt="" :key="index" v-for="(
                          paymentMethod, index
                        ) in paymentMethods.site_details.filter(
                            (e) => e.title !== 'Cash On Delivery'
                          )" />
                        </figure>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="form-group text-center">
                  <button v-if="!confirmLoading" :disabled="invalid" class="confirm_btn" type="submit">
                    Confirm
                  </button>
                  <button v-if="confirmLoading" disabled class="confirm_btn" type="button">
                    <img style="height: 20px; width: 20px !important;" src="../../../assets/oading.gif" alt="">
                    Confirming
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>


    </div>

    <b-modal id="bv-modal-example" hide-footer>
      <div class="d-block text-center">
        <h3>Your order has been place successfully</h3>
      </div>
      <b-button class="mt-3">Ok</b-button>
    </b-modal>
    <!--<button>sf</button>-->
  </section>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { mapGetters } from 'vuex'
import axios from "axios";

export default {
  name: 'billing',
  components: {
    ValidationObserver,
  },
  watch: {
    divisions(val) {
      if (val.length) {
        this.shipping.division_id = val[0].division_id
      }
    },
    divisionsBilling(val) {
      if (val.length) {
        this.shipping.billing_division_id = val[0].division_id
      }
    },
    districts(val) {
      if (val.length) {
        this.shipping.district_id = val[0].district_id
        this.areas = val
        console.log('watch log', this.shipping.district_id, this.total_amt)
        this.$store.dispatch('getShippingCharge', {
          district_id: this.shipping.district_id,
          total_amount: this.total_amt,
        })
      }
    },
    districtsBilling(val) {
      if (val.length) {
        this.shipping.billing_district_id = val[0].district_id
        this.areasBilling = val
      }
    },
    coupon_code(val) {
      if (!val) {
        this.coupon_msg = ''
      }
    },
    total(val) {
      this.total_amt = val;
      if (this.shipping.district_id) {
        this.$store.dispatch('getShippingCharge', {
          district_id: this.shipping.district_id,
          total_amount: this.total_amt,
        })
      } else {
        this.$store.dispatch('getShippingCharge', {
          district_id: 1,
          total_amount: this.total_amt,
        })
      }
    }
  },
  data() {
    return {
      deleveryMethod: true,
      selectedBilling: true,
      divisions: [],
      districts: [],
      areas: [],
      divisionsBilling: [],
      districtsBilling: [],
      areasBilling: [],
      shipping: {
        name: '',
        customer_id: '',
        shipping_charge: 0,
        phone: '',
        email: '',
        address: '',
        division_id: '',
        district_id: '',
        area_id: '',
        billing_name: '',
        billing_phone: '',
        billing_email: '',
        billing_address: '',
        billing_division_id: '',
        billing_district_id: '',
        billing_area_id: '',
        comments: '',
        terms_and_condition: true,
        payment_getway: 'Cash on delivery',
        products: [],
        coupon_code: '',
        total_amt_digi_m: '',
        shipping_crg_digi_m: ''
      },
      showError: false,
      cupon: '',
      activeCupon: false,
      coupon_code: '',
      total_amt: 0,
      isApplyCoupon: false,
      coupon_msg: '',
      confirmLoading: false,
      user: {
        email: "",
        password: "",
      },
      resError: '',
      showModal: false,
    }
  },
  mounted() {
    this.$store.dispatch('setLocaitons')
    this.shoppingBagmap()
    console.log('countGroupProduct', this.$store.state);
    //this.shippingCharge.shipping_charge = 60
    if (this.customer) {
      this.shipping.name = this.customer.customer.name;
      this.shipping.email = this.customer.customer.email;
      this.shipping.phone = this.customer.customer.phone;
    }

    this.total_amt = this.total;
    this.$store.dispatch('getShippingCharge', {
      district_id: 1,
      total_amount: this.total_amt,
    })
    console.log("initial total", this.total_amt)
    // Pixel Setup Begin
    let allData = []
    let mData = {
      item_name: '',
      item_id: '',
      price: '',
      item_brand: '',
      item_category: '',
      item_category2: '',
      item_category3: '',
      item_category4: '',
      item_variant: '',
      item_list_name: '',
      item_list_id: '',
      index: 0,
      quantity: '',
    }
    this.shoppingBag.forEach(item => {
      mData.item_name = item.name
      mData.item_id = item.id
      mData.price = item.unitPrice
      mData.item_brand = item.brand_id
      mData.quantity = item.qty
      allData.push(mData)
    })
    window.fbq('track', 'InitiateCheckout', { value: this.total_amt, currency: 'BDT', contents: allData, content_type: "product" })
    //console.log("pixel data = ", allData);
    //window.dataLayer.push({ ecommerce: null });
    // window.dataLayer.push({
    //   event    : "begin_checkout",
    //   ecommerce: {
    //     items: allData
    //   }
    // });
    // Pixel Setup End
  },
  computed: {
    ...mapGetters([
      'locations',
      'paymentMethods',
      'shoppingBag',
      'total',
      'customer',
      'shippingCharge',
      'me',
      'accessToken',
      'siteSetting',
    ]),
  },
  methods: {
    bill(e) {
      this.confirmLoading = true;
      e.preventDefault()
      this.shipping.billing_division_id = this.divisions.id
      this.shipping.billing_district_id = this.districts.id
      this.shipping.billing_address = this.shipping.address
      this.shipping.billing_area_id = this.shipping.area_id || ''
      this.shipping.billing_name = this.shipping.name
      this.shipping.billing_email = this.shipping.email
      this.shipping.billing_phone = this.shipping.phone
      this.shipping.shipping_charge = this.shippingCharge.shipping_charge
      this.shipping.coupon_code = this.coupon_code

      this.shipping.payment_getway = this.deleveryMethod === true ? "Cash on delivery" : this.deleveryMethod === '2' ? "Bkash" : this.deleveryMethod === '3' ? "Online Payment" : "Cash on delivery";
      if (this.customer) {
        this.shipping.customer_id = this.customer.customer.id
      }
      this.shipping.total_amt_digi_m = this.total_amt;
      let dataForDigiMarketers = {
        shippingData: this.shipping,
        cartData: this.shoppingBag,
      }
      this.$store.dispatch('validPurchaseItemsForDigitalMarketers', dataForDigiMarketers);

      this.$store.dispatch('billing', this.shipping).then((res) => {
        console.log('Log-billing-->', res)
        this.confirmLoading = false;
        // if (res === undefined) {
        //   this.$swal({
        //     text: 'Yor order has been place successfully.',
        //     confirmButtonColor: '#65c03a'
        //   });
        //   //this.$store.dispatch('nullCart')
        //
        // } else {
        //   window.alert('Something Wrong !')
        // }
      })
    },
    // countGroupProduct(groupProductId) {
    //   return this.shoppingBag.reduce((total, item) => {
    //     // return item.group_product_id === groupProductId ? total + item.quantity : total;
    //     if (item.group_product_id find more 1 groupProductId ) {

    //       return item.group_product_id === groupProductId ? total + this.$store.getters.prodQty(item.id) : total;
    //     }
    //   }, 0);
    // }
    countGroupProduct(groupProductId) {
      let count = 0;
      let totalQuantity = 0;

      this.shoppingBag.forEach(item => {
        if (item.group_product_id === groupProductId) {
          count++;
          totalQuantity += this.$store.getters.prodQty(item.id);
        }
      });

      // Return total quantity only if there are multiple products in the group
      return count > 1 ? totalQuantity : 0;
    },
    shoppingBagmap() {
      if (this.shoppingBag.length) {
        const mapBag = this.shoppingBag.map((item) => {
          return {
            id: item.id,
            quantity: item.qty,
          }
        })
        this.shipping.products = mapBag
      }
      console.log('clock = ', this.shipping.products)
    },
    removeFromCart(item) {
      this.$store.dispatch('removeFromCart', item)
    },
    showorhideCheekedOpt() {
    },
    addQty(product) {
      console.log('Product add one ==>', product)
      // console.log('update', product.product_price[0].tier_quantity_three)
      this.$store.dispatch('addQty', product)
      this.shoppingBagmap()
    },
    deductQty(product) {
      if (this.$store.getters.prodQty(product.id) > 0) {
        this.$store.dispatch('deductQty', product)
      }
      if (this.$store.getters.prodQty(product.id) === 0) {
        this.$store.dispatch('removeFromCart', product)
      }
      this.shoppingBagmap()
    },
    applyCoupon(coupon_code) {
      axios.post(`https://savershall.com/sh_backapi/api/coupons?coupon_code=${coupon_code}`).then(response => {
        console.log("coupon = ", response);
        if (response.data.data.customer_id === null) {
          if (response.data.data.coupon_type === "flate") {
            this.total_amt = this.total_amt - parseInt(response.data.data.value);
            this.isApplyCoupon = true;
            this.coupon_msg = "Coupon Applied for flat discount " + response.data.data.value + "৳"
          }
          if (response.data.data.coupon_type === "percentage") {
            let pers = Math.ceil((parseInt(response.data.data.value) / 100) * this.total_amt);
            this.total_amt = this.total_amt - pers;
            this.isApplyCoupon = true;
            this.coupon_msg = "Coupon Applied for discount " + response.data.data.value + "% amount of " + pers + "৳"
          }
          if (response.data.data.coupon_type === "freeshipping") {
            this.shippingCharge.shipping_charge = 0;
            this.isApplyCoupon = true;
            this.coupon_msg = "Coupon Applied for Free Shipping."
          }
        }
        if (response.data.data.customer_id !== null) {
          if (response.data.data.customer_id === this.customer.id) {
            if (response.data.data.coupon_type === "flate") {
              this.total_amt = this.total_amt - parseInt(response.data.data.value);
              this.isApplyCoupon = true;
              this.coupon_msg = "Coupon Applied for flat discount " + response.data.data.value + "৳"
            }
            if (response.data.data.coupon_type === "percentage") {
              let pers = Math.ceil((parseInt(response.data.data.value) / 100) * this.total_amt);
              this.total_amt = this.total_amt - pers;
              this.isApplyCoupon = true;
              this.coupon_msg = "Coupon Applied for discount " + response.data.data.value + "% amount of " + pers + "৳"
            }
            if (response.data.data.coupon_type === "freeshipping") {
              this.shippingCharge.shipping_charge = 0;
              this.isApplyCoupon = true;
              this.coupon_msg = "Coupon Applied for Free Shipping."
            }
          } else {
            this.coupon_msg = "You are not eligible for this coupon."
          }
        }
      })
        .catch(error => {
          console.log(error)
          this.coupon_msg = "Coupon not found."
          this.isApplyCoupon = false;
        })
    },
    customerLogin(e) {
      e.preventDefault();
      if (this.user.email === "" || this.user.password === "") {
        this.$toasted.error("Please insert value in Username or Password field.");
      } else {
        this.$store.dispatch("login", this.user).then((res) => {
          if (this.accessToken) {
            this.$toasted.success('Successfully Logedin.');
            console.log("========", this.customer)
            this.shipping.name = this.customer.customer.name;
            this.shipping.email = this.customer.customer.email;
            this.shipping.phone = this.customer.customer.phone;
            this.user = {
              email: "",
              password: ""
            };
          } else {
            this.$toasted.error("Invalid Credentials.");
            this.resError = res.message;
          }
        });
      }

    },
    loginFacebook() {
      window.location.href = 'https://savershall.com/sh_backapi/login/facebook'
    },
    googleLogin() {
      window.location.href = 'https://savershall.com/sh_backapi/auth/google'
    }
    // goToPayment(paymentMethod, amt){
    //   this.shipping.billing_division_id = this.divisions.id
    //   this.shipping.billing_district_id = this.districts.id
    //   this.shipping.billing_address = this.shipping.address
    //   this.shipping.billing_area_id = this.shipping.area_id || ''
    //   this.shipping.billing_name = this.shipping.name
    //   this.shipping.billing_email = this.shipping.email
    //   this.shipping.billing_phone = this.shipping.phone
    //   this.shipping.shipping_charge = this.shippingCharge.shipping_charge
    //   this.shipping.coupon_code = this.coupon_code
    //   this.shipping.payment_getway = paymentMethod.title
    //   this.shipping.ammount = amt
    //   if (this.customer) {
    //     this.shipping.customer_id = this.customer.customer.id
    //   }
    //   console.log("paymentMethod", this.shipping);
    //   this.$store.dispatch('onlinePayment', this.shipping).then((res) => {
    //     console.log('Log-billing-->', res)
    //     if (res === undefined) {
    //       this.$swal({
    //         text: 'Yor order has been place successfully.',
    //         confirmButtonColor: '#65c03a'
    //       });
    //       this.$store.dispatch('nullCart')
    //
    //     } else {
    //       //window.alert('Something Wrong !')
    //     }
    //   })
    // }
  },
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.cupon_btn {
  cursor: pointer;
}

h5.unitPrice {
  font-size: 0.9rem;
  color: #7a7a8f;
}

.checkout_form .btn {
  margin: 15px 25px 0 0;
  background: #1F1F1F;
  letter-spacing: 2px;
  border-radius: 4px;
  min-width: 125px;
  font-size: 16px;
  color: #fff;
  transition: all 0.2s;
}

.others_form a {
  text-transform: uppercase;
  padding: 9px 20px 5px;
  display: inline-table;
  border-radius: 4px;
  text-align: center;
  color: #fff;
  width: 100%;
  transition: all 0.2s;
}

.others_form .btn_list {
  margin-bottom: 0;
  width: 100%;
}

.others_form li {
  margin: 30px 0;
  width: 100%;
}

.others_form a.google {
  background: #D34736;
}

.others_form a.facebook {
  background: #3B5999;
}

.checkout_form .forgot {
  text-transform: uppercase;
  display: inline-block;
  letter-spacing: 1px;
  margin: 15px 0 0;
  color: #464646;
}

.login-ar {
  padding: 12px;
  background: #037bff;
  border-radius: 5px;
  text-align: center;
  color: #fff;
}

.login-ar span {
  cursor: pointer;
  color: #fff;
}

.login-ar span:hover {
  color: #fff;
}

.mobile-login {
  display: none;
}

@media (min-width: 250px) and (max-width: 575.98px) {
  .desktop-login {
    display: none;
  }

  .mobile-login {
    display: block;
  }
}

@media (min-width: 575.99px) and (max-width: 991.98px) {
  .desktop-login {
    display: none;
  }

  .mobile-login {
    display: block;
  }
}
</style>
