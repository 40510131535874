<template>
  <div>
    <PaymentSection></PaymentSection>
  </div>
</template>
<script>
import PaymentSection from "@/components/frontend/payment/PaymentSection.vue";
export default {
  name: "Checkout",
  components: {
    PaymentSection,
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss">
@import "@/assets/frontend/style/payment.scss";
</style>
